import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export function formatDate({ date = '', isoFormat = 'dd MMMM, yyy' }) {
  if (date) {
    return format(new Date(date), isoFormat, { locale: es });
  }

  return '';
}

export default this;
