import React from 'react';

import logo from '../../../images/logo.png';
import facebook from '../../../icon/facebook.svg';
import twitter from '../../../icon/twitter.svg';
import imstagram from '../../../icon/instagram.svg';

import './footer.css';

const Footer = ({ facebookLink, twitterLink, instagramLink }) => (
  <footer className="bg-blue">
    <div className="row justify-content-center">
      <div className="col center">
        <img alt="logo" className="footer-logo" src={logo} />
      </div>

      <div className="col justify-content-center footer-tex">
        <span className="text-white block text-center">{`© ${new Date().getFullYear()} Todos los derechos reservados.`}</span>
        <span className="text-white block text-center">Desarrolado por</span>

        <a
          href="https://www.eboxcode.com"
          target="_blanck"
          className="text-yellow block text-center font-medium footer-ebox-page"
        >
          ebox Code, Software Development
        </a>
      </div>

      <div className="col footer-link-div">
        <div className="row">
          {facebookLink && (
            <a href={facebookLink} target="_blank" className="footer-link bg-yellow" rel="noreferrer">
              <img alt="facebook" src={facebook} className="footer-link-img" />
            </a>
          )}

          {twitter && (
            <a href={twitterLink} target="_blank" className="footer-link bg-yellow" rel="noreferrer">
              <img alt="twitter" src={twitter} className="footer-link-img" />
            </a>
          )}

          {instagramLink && (
            <a href={instagramLink} target="_blank" className="footer-link bg-yellow" rel="noreferrer">
              <img alt="imstagram" src={imstagram} className="footer-link-img" />
            </a>
          )}
        </div>
      </div>
    </div>
  </footer>
);

export { Footer };
