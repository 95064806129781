import React, { useRef, useState } from 'react';

const SubMenu = ({ title, items, onClickMenu }) => {
  const menuItem = useRef(null);
  const [show, setShow] = useState(false);

  function renderSubItem() {
    return (
      <ul className="sub-menu-item">
        {items.map((item, index) => (
          <li className="" key={String(index)}>
            <a href={item.route} target={item.target} onClick={onClickMenu} aria-current="page">
              <span className="text-blue">{item.name}</span>
            </a>
          </li>
        ))}
      </ul>
    );
  }

  function onMenuFocus() {
    setShow(true);
  }

  function onMenuBlur() {
    setShow(false);
  }

  return (
    <>
      <li className="sub-menu" onBlur={onMenuBlur}>
        <a className="" onFocus={onMenuFocus}>{title}</a>

        {renderSubItem()}
      </li>
    </>
  );
};

export default SubMenu;
