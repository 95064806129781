import React, { useEffect, useState } from 'react';
import { useScrollRestoration } from 'gatsby';

import { Img } from '../img';
import SubMenu from './SubMenu';

import logoImg from '../../../images/logo.png';

import { dev } from '../../../data/constant';
import './nav.css';

const MELVIL_JONES = [
  {
    name: 'Biografia MJ ',
    route: 'https://www.lionsclubs.org/es/discover-our-clubs/melvin-jones-biography',
    target: '_blank',
  },
  {
    name: 'Solicitud y Mención AMJ',
    route: 'https://cdn2.webdamdb.com/md_27FYVjNeWb47.jpg.pdf',
    target: '_blank',
  },
];

const origin = dev ? 'http://localhost:8000' : 'https://leonesdistritob9.org';

const Nav = ({ clubs = [], logo, rootPath = '', whiteTheme = false }) => {
  const scrollRestoration = useScrollRestoration('nav');
  const [showMenu, setShowMenu] = useState(null);
  const [isScrolled, setScrolled] = useState(false);

  const listWhiteClass = `line-${isScrolled ? 'black' : 'white'}`;

  const isHome = clubs.length > 0;
  const contactLink = isHome ? 'contacto' : `${rootPath}/contacto`;

  function onScroll() {
    if (window.scrollY === 0) {
      setScrolled(false);
    } else if (!isScrolled) {
      setScrolled(true);
    }
  }

  useEffect(() => {
    if (window.location.href.includes('#')) {
      return;
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
      scrollRestoration.onScroll();
    }, 50);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isScrolled]);

  function toggleMenu() {
    if (showMenu) {
      setShowMenu(null);
    } else {
      setShowMenu('menu-show');
    }
  }

  function onTouchOutMenu() {
    // setShowMenu(null);
  }

  function onClickMenu() {
    toggleMenu();
  }

  return (
    <>
      {showMenu && (
        <div
          aria-label="overlay"
          className="over no-desktop"
          onClick={toggleMenu}
          onKeyPress={toggleMenu}
          role="button"
          tabIndex={-1}
        />
      )}

      <nav
        id="nav"
        className={`${listWhiteClass ? 'nav-over' : ''} ${isScrolled ? 'nav-colored' : ''}`}
        {...scrollRestoration}
      >
        <Img
          alt="club de leones"
          className="logo-black"
          src={logo || logoImg}
        />

        <div
          className={`menu ${showMenu || ''}`}
          onClick={onTouchOutMenu}
          role="button"
          tabIndex={0}
          onKeyDown={null}
        >
          <ul className={(whiteTheme && !showMenu) ? 'ul-white' : ''}>
            <li className="">
              <a className="" onClick={onClickMenu} aria-current="page" href="/">Home</a>
            </li>

            <li className="">
              <a className="" onClick={onClickMenu} href={`${rootPath}#nosotros`}>Nosotros</a>
            </li>

            {isHome
              ? (
                <SubMenu
                  items={clubs.map((item) => ({ ...item, route: `/${item.route}` }))}
                  onClickMenu={onClickMenu}
                  title="Club de Leones +"
                />
              )
              : (
                <li className="">
                  <a className="" onClick={onClickMenu} href="/">Distrito</a>
                </li>
              )}

            <li className="">
              <a className="" onClick={onClickMenu} href={`${rootPath}#eventos`}>Eventos</a>
            </li>

            <SubMenu items={MELVIL_JONES} onClickMenu={onClickMenu} title="Melvin Jones +" />

            <li className="">
              <a className="" onClick={onClickMenu} href={`${origin}/${contactLink}`}>Contacto</a>
            </li>
          </ul>
        </div>

        <div
          aria-expanded={!!showMenu}
          className={`menu-bar ${showMenu ? 'animate-button' : ''}`}
          onClick={toggleMenu}
          onKeyPress={toggleMenu}
          role="button"
          tabIndex={0}
        >
          <div className={`one ${whiteTheme ? listWhiteClass : ''}`} />
          <div className={`two ${whiteTheme ? listWhiteClass : ''}`} />
          <div className={`three ${whiteTheme ? listWhiteClass : ''}`} />
        </div>
      </nav>
    </>
  );
};

export { Nav };
