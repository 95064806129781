import React from 'react';
import Healmet from 'react-helmet';

import favicon from '../../../images/icon.png';

import {
  url,
  defaultDescription,
  social,
  defaultTitle,
  socialLinks,
  address,
  contact,
  legalName,
  foundingDate,
  logo,
} from '../../../data/config';

// import Thumbnail from '../../../assets/thumbnail/thumbnail.png';

const SEO = ({
  title = defaultTitle,
  description = defaultDescription,
  location = '',
}) => {
  const structuredDataOrganization = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    legalName,
    url,
    logo,
    foundingDate,
    founders: [{
      '@type': 'Person',
      name: legalName,
    }],
    contactPoint: [{
      '@type': 'ContactPoint',
      email: contact.email,
      telephone: contact.phone,
      contactType: 'customer service',
    }],
    address: {
      '@type': 'PostalAddress',
      addressLocality: address.city,
      addressRegion: address.region,
      addressCountry: address.country,
      postalCode: address.zipCode,
    },
    sameAs: [
      socialLinks.twitter,
      socialLinks.google,
      socialLinks.youtube,
      socialLinks.instagram,
    ],
  };

  return (
    <Healmet>
      <meta name="description" content={description} />
      {/* <meta name="image" content={Thumbnail} /> */}
      <link rel="icon" href={favicon} />

      <meta property="og:url" content={`${url}${location}/?ref=smakosh.com`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:image" content={Thumbnail} /> */}
      <meta property="fb:app_id" content={social.facebook} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={socialLinks.twitter} />
      <meta name="twitter:site" content={social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* <script type="application/ld+json">{JSON.stringify(structuredDataOrganization)}</script> */}
      <link rel="publisher" href={socialLinks.google} />
      <title>{title}</title>
      {/* <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.1/dist/umd/popper.min.js" integrity="sha384-SR1sx49pcuLnqZUnnPwx6FCym0wLsk5JZuNx2bPPENzswTNFaQU1RDvt3wT4gWFG" crossorigin="anonymous" />
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.min.js" integrity="sha384-j0CNLUeiqtyaRmlzUHCPZ+Gy5fQu0dQ6eZ/xAww941Ai1SxSY+0EQqNXNE6DZiVc" crossorigin="anonymous" /> */}
      <html lang="es" dir="ltr" />

      <noscript>
        {'<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NHZG8GS" height="0" width="0" style="display:none;visibility:hidden" />'}
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </Healmet>
  );
}

export { SEO }
