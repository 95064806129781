import React from 'react';

import { SEO } from '../common/seo';
import { Nav } from '../common/nav';
import { Footer } from '../common/footer';

import './colors.css';
import './font.css';
import './layout.css';
import './loading.css';

const Layout = ({
  children,
  facebookLink,
  instagramLink,
  logo,
  menu,
  rootPath,
  twitterLink,
  whiteTheme = false,
}) => (
  <>
    <SEO />

    <Nav clubs={menu} logo={logo} rootPath={rootPath} whiteTheme={whiteTheme} />

    <main>
      {children}
    </main>

    <Footer
      facebookLink={facebookLink}
      instagramLink={instagramLink}
      twitterLink={twitterLink}
    />
  </>
);

export { Layout };
