import React from 'react';

const url = process.env.DEV ? 'http://localhost:3333/api' : 'https://admin.leonesdistritob9.org/api';

const Img = ({ alt, className, src = '', style = {} }) => (
  <img
    alt={alt}
    className={className}
    src={src.includes('public') ? `${url}/${src}` : src}
    style={style}
  />
);

export { Img };
