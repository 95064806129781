export const dev = process.env.DEV;
export const url = process.env.URL_API;
export const URL_BASE = process.env.URL_API;
export const googleMapKey = 'AIzaSyCSLA6ZkWXVJnJOAUjucKPHvViPM6THJd8';

export const RUBLOS_ID = {
  vision: {
    id: 1,
    name: 'Visión',
  },
  hambre: {
    id: 2,
    name: 'Mitigar el Hambre',
  },
  ambiente: {
    id: 3,
    name: 'Medio Ambiente',
  },
  diabetes: {
    id: 4,
    name: 'Diabetes',
  },
  cancer: {
    id: 5,
    name: 'Cáncer Infantil',
  },
};
