import { URL_BASE } from '../data/constant';

export function isEmailValid(email = '') {
  const EMAIL_REGEX =
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;

  return EMAIL_REGEX.test(email);
}

export function isOnlyNumber(number = 0) {
  const ONLY_NUMBER = /[0-9]$/;

  return ONLY_NUMBER.test(number);
}

// serializeForUri({ param0: 'value 0', param1: 'value:1' }) ===
// 'param0=value%200&param1=value%3A1'
function serializeForUri(obj = {}) {
  return Object
    .keys(obj)
    .map((key) => `${encodeURI(key)}=${encodeURI(obj[key])}`)
    .join('&');
}

async function apiFetch({ body, headers, method, params = {}, url }) {
  let rest = {};

  if (method === 'POST') {
    rest = { body: JSON.stringify(body) }
  }

  return fetch(`${URL_BASE}/${url}?${serializeForUri(params)} `, {
    method,
    headers: { 'Content-Type': 'application/json', ...headers },
    ...rest,
  });
}

export async function apiPost({ body, headers = {}, url }) {
  try {
    const request = await apiFetch({ body, headers, method: 'POST', url });
    let data = null;

    if (request.ok && request.status === 200) {
      data = await request.json();
    }

    return { data, isValid: request.ok };
  } catch (error) {
    return { data: null, isValid: false, mesage: error.mesage };
  }
}
